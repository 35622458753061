.home-container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-header {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: relative;
  max-width: 1110px;
  align-items: center;
  padding-right: 4px;
  flex-direction: row;
}
.home-logo {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image {
  width: 140px;
  height: auto;
  object-fit: cover;
}
.home-container01 {
  flex: 1;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.home-menu {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
}
.home-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 101;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
}
.home-logo1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image1 {
  width: 100px;
  object-fit: cover;
}
.home-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon {
  width: 24px;
  height: 24px;
}
.home-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text01 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text01:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text02 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text02:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text03 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text03:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text04 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text04:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text05 {
  transition: 0.3s;
}
.home-text05:hover {
  color: var(--dl-color-turquoise-default);
}
.home-bot {
  flex: 0 0 auto;
  display: flex;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}
.home-container02 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link {
  display: contents;
}
.home-container03 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon02 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon02:hover {
  fill: var(--dl-color-purple-default);
}
.home-link01 {
  display: contents;
}
.home-container04 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon04 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon04:hover {
  fill: var(--dl-color-pink-default);
}
.home-link02 {
  display: contents;
}
.home-container05 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon06 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon06:hover {
  fill: var(--dl-color-turquoise-default);
}
.home-link03 {
  display: contents;
}
.home-container06 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon08 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon08:hover {
  fill: var(--dl-color-orange-default);
}
.home-main {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-blur-background {
  top: -148px;
  left: 0px;
  right: 0px;
  width: 2017px;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  filter: blur(60px);
  height: 789px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  margin-right: auto;
  flex-direction: column;
  justify-content: center;
  background-color: #f2f2f2;
}
.home-hero {
  width: 1028px;
  height: 578px;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: relative;
  max-width: 1110px;
  align-items: center;
  padding-right: 2rem;
  flex-direction: row;
  justify-content: space-between;
}
.home-container07 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text06 {
  width: 472px;
  font-size: 44px;
  font-family: "Inter";
  font-weight: 800;
  line-height: 1;
  margin-bottom: var(--dl-space-space-doubleunit);
  padding-right: 2px;
  text-transform: none;
  text-decoration: none;
}
.home-text15 {
  max-width: 500px;
  align-self: flex-start;
  text-align: left;
}
.home-text16 {
  font-style: normal;
  font-weight: 700;
}
.home-text18 {
  font-style: normal;
  font-weight: 400;
}
.home-text19 {
  font-style: normal;
  font-weight: 400;
}
.home-text20 {
  font-style: normal;
  font-weight: 400;
}
.home-text21 {
  font-style: normal;
  font-weight: 400;
}
.home-text22 {
  font-style: normal;
  font-weight: 400;
}
.home-text23 {
  font-style: normal;
  font-weight: 400;
}
.home-link04 {
  display: contents;
}
.home-component {
  text-decoration: none;
}
.home-image2 {
  width: 50%;
  height: auto;
  max-width: 400px;
  object-fit: cover;
}
.home-turquoise-cirble {
  top: 439px;
  left: auto;
  right: 430px;
  width: 170px;
  bottom: auto;
  filter: blur(60px);
  opacity: 0.6;
  position: absolute;
  object-fit: cover;
}
.home-purple-circle {
  top: -100px;
  left: auto;
  right: -26px;
  width: 400px;
  bottom: auto;
  filter: blur(60px);
  opacity: 0.4;
  position: absolute;
  object-fit: cover;
}
.home-features {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text27 {
  max-width: 500px;
  text-align: center;
}
.home-features1 {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-testimonials {
  width: 100%;
  height: 418px;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 29px;
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: 36px;
  justify-content: center;
  background-color: var(--dl-color-turquoise-100);
}
.home-feature1 {
  width: 2135px;
  height: 293px;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-self: center;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;
}
.home-container08 {
  flex: 0 0 auto;
  width: 540px;
  height: 237px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image3 {
  width: 200px;
  object-fit: cover;
}
.home-container09 {
  flex: 1;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  text-align: right;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text30 {
  width: 100%;
  font-size: 32px;
  align-self: flex-end;
}
.home-text31 {
  max-width: 400px;
  align-self: flex-end;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-container10 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  flex-wrap: wrap;
  max-width: 1100px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-feature11 {
  width: 100%;
  height: 334px;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container11 {
  flex: 0 0 auto;
  width: 540px;
  height: 257px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image4 {
  width: 200px;
  object-fit: cover;
}
.home-container12 {
  flex: 1;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text32 {
  width: 100%;
  font-size: 32px;
}
.home-text33 {
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-feature12 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-self: center;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;
}
.home-container13 {
  flex: 0 0 auto;
  width: 540px;
  height: 237px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image5 {
  width: 200px;
  object-fit: cover;
}
.home-container14 {
  flex: 1;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  text-align: right;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text34 {
  width: 438px;
  font-size: 32px;
}
.home-text35 {
  max-width: 400px;
  align-self: flex-end;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-feature2 {
  width: 100%;
  height: 73px;
  display: flex;
  z-index: 100;
  max-width: 1110px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
.home-container15 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-text36 {
  width: 294px;
  height: 59px;
  font-size: 34px;
  text-align: center;
}
.home-container16 {
  width: 740px;
  height: 82px;
  display: flex;
  margin-top: 30px;
  align-items: center;
  margin-left: 56px;
  margin-right: 56px;
  margin-bottom: 118px;
  justify-content: center;
}
.home-link05 {
  color: var(--dl-color-gray-white);
  width: 300px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  text-decoration: none;
  background-color: #8c30f5;
}
.home-link06 {
  color: var(--dl-color-gray-white);
  width: 300px;
  font-style: normal;
  text-align: center;
  font-weight: 600;

  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  text-decoration: none;
  background-color: var(--dl-color-orange-800);
}
.home-footer {
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.home-container17 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-image6 {
  width: 200px;
  object-fit: cover;
}
.home-divider {
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: var(--dl-color-gray-white);
}
.home-container18 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text37 {
  color: var(--dl-color-gray-300);
  margin-top: var(--dl-space-space-unit);
}
.home-container19 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link07 {
  display: contents;
}
.home-container20 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  text-decoration: none;
  background-color: var(--dl-color-gray-900);
}
.home-icon10 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon10:hover {
  fill: var(--dl-color-purple-default);
}
.home-link08 {
  display: contents;
}
.home-container21 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  padding-bottom: 0.5rem;
  text-decoration: none;
  background-color: var(--dl-color-gray-900);
}
.home-icon12 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
}
.home-link09 {
  display: contents;
}
.home-container22 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  padding-bottom: 0.5rem;
  text-decoration: none;
  background-color: var(--dl-color-gray-900);
}
.home-icon16 {
  fill: white;
  width: 15px;
  height: 16px;
}
@media (max-width: 991px) {
  .home-hero {
    height: auto;
    flex-direction: column;
  }
  .home-container07 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-text06 {
    text-align: center;
  }
  .home-text15 {
    align-self: center;
    text-align: center;
  }
  .home-features {
    height: 878px;
  }
  .home-testimonials {
    height: 558px;
  }
  .home-feature1 {
    height: 517px;
    margin-top: 0px;
    flex-direction: column;
  }
  .home-container09 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-container10 {
    flex-direction: column;
  }
  .home-feature11 {
    flex-direction: column;
  }
  .home-container11 {
    height: 292px;
    margin-top: 232px;
  }
  .home-container12 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-feature12 {
    flex-direction: column;
  }
  .home-container13 {
    margin-top: 219px;
  }
  .home-container14 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-container15 {
    align-items: flex-start;
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .home-menu {
    display: none;
  }
  .home-text06 {
    font-size: 40px;
    text-align: center;
    font-family: Inter;
    font-weight: 800;
    line-height: 1;
    text-transform: none;
    text-decoration: none;
  }
  .home-features {
    height: auto;
  }
  .home-text26 {
    font-size: 40px;
  }
  .home-testimonials {
    height: 667px;
  }
  .home-container18 {
    flex-direction: column;
  }
  .home-text37 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media (max-width: 479px) {
  .home-header {
    padding-right: 28px;
  }
  .home-container01 {
    height: 50px;
  }
  .home-bot {
    width: 100%;
  }
  .home-container07 {
    width: 347px;
  }
  .home-text06 {
    color: var(--dl-color-gray-900);
    width: 423px;
    height: 157px;
    font-size: 34px;
    font-family: Inter;
    font-weight: 800;
    line-height: 1;
    margin-bottom: 7px;
    text-transform: none;
    text-decoration: none;
  }
  .home-text15 {
    align-self: center;
    text-align: center;
  }
  .home-text16 {
    font-style: normal;
    font-weight: 700;
  }
  .home-text18 {
    font-style: normal;
    font-weight: 400;
  }
  .home-text19 {
    font-style: normal;
    font-weight: 400;
  }
  .home-text20 {
    font-style: normal;
    font-weight: 400;
  }
  .home-text21 {
    font-style: normal;
    font-weight: 400;
  }
  .home-text22 {
    font-style: normal;
    font-weight: 400;
  }
  .home-text23 {
    font-style: normal;
    font-weight: 400;
  }
  .home-text24 {
    font-style: normal;
    font-weight: 400;
  }
  .home-image2 {
    width: 400px;
  }
  .home-features {
    height: 1493px;
  }
  .home-text26 {
    text-align: center;
  }
  .home-text27 {
    text-align: center;
  }
  .home-testimonials {
    height: 492px;
    background-color: White (Gray);
  }
  .home-feature1 {
    height: 480px;
  }
  .home-container09 {
    text-align: center;
  }
  .home-container11 {
    height: 233px;
    margin-top: 167px;
  }
  .home-container12 {
    text-align: center;
  }
  .home-feature12 {
    height: 466px;
  }
  .home-container13 {
    height: 148px;
    margin-top: 235px;
  }
  .home-image5 {
    margin-bottom: 0px;
  }
  .home-container14 {
    text-align: center;
  }
  .home-text35 {
    align-self: center;
  }
  .home-text36 {
    width: 479px;
    height: 102px;
    margin-top: 66px;
  }
  .home-container16 {
    width: 434px;
    height: 145px;
    margin-left: 26px;
    margin-right: 20px;
  }
}

.text-center {
  text-align: center;
}
.padding-20 {
  padding: 20px;
}

.padding-top-20 {
  padding-top: 20px;
}
.padding-bottom-20 {
  padding-bottom: 20px;
}
.padding-left-20 {
  padding-left: 20px;
}
.padding-right-20 {
  padding-right: 20px;
}
.padding-x-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.padding-y-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.padding-40 {
  padding: 40px;
}
.padding-top-40 {
  padding-top: 40px;
}
.padding-bottom-40 {
  padding-bottom: 40px;
}
.padding-left-40 {
  padding-left: 40px;
}
.padding-right-40 {
  padding-right: 40px;
}
.padding-x-40 {
  padding-left: 40px;
  padding-right: 40px;
}
.padding-y-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.padding-60 {
  padding: 60px;
}
.padding-top-60 {
  padding-top: 60px;
}
.padding-bottom-60 {
  padding-bottom: 60px;
}
.padding-left-60 {
  padding-left: 60px;
}
.padding-right-60 {
  padding-right: 60px;
}
.padding-x-60 {
  padding-left: 60px;
  padding-right: 60px;
}
.padding-y-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.padding-80 {
  padding: 80px;
}
.padding-top-80 {
  padding-top: 80px;
}
.padding-bottom-80 {
  padding-bottom: 80px;
}
.padding-left-80 {
  padding-left: 80px;
}
.padding-right-80 {
  padding-right: 80px;
}
.padding-x-80 {
  padding-left: 80px;
  padding-right: 80px;
}
.padding-y-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
.vision-image {
  margin: 10px;
  border-radius: 40px;
  width: 300px;
  height: 300px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.member-image {
  margin: 10px;
  border-radius: 40px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
